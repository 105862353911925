.family-tree__selection-menu__wrapper {
  position: absolute;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  border: 1px solid $gray-8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  max-height: 530px;
  overflow-y: auto;
  max-width: 300px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

  hr {
    width: 100%;
    border-top: 1px solid $gray-8;
  }

  p {
    font-family: 'Lato', sans-serif !important;
    font-weight: 500;
  }

  .title-rows {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;

    label {
      font-weight: 500;
    }

    > label {
      margin-left: 8px;
    }
  }

  .title-rows:first-child {
    margin-top: 10px;
  }

  .title-rows:last-child {
    margin-bottom: 10px;
  }
}

.family-tree__selection-menu__item-chip {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  padding: 4px 8px;
  margin: 5px 2px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
  border-radius: 2rem;
  justify-content: space-between;
  min-width: 75px;
  max-width: fit-content;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: $gray-9;
  }
}

.family-tree__selection-menu__existing-item-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.family-tree__selection-menu__item-chip--selected {
  background-color: $gray-9;
}

.family-tree__rearrange-button__wrapper {
  position: absolute;
  z-index: 999;
  right: 100px;
  bottom: 50px;
  background: white;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 28px;
  }

  img {
    margin-left: 10px;
  }
}

.family-tree__relationship-editor-button__wrapper {
  position: absolute;
  z-index: 999;
  right: 100px;
  bottom: 150px;
  background: white;
  border: 1px solid rgb(204, 204, 204);
}

.family-tree__character-details__wrapper {
  position: absolute;
  width: fit-content;
  max-width: 300px;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  border: 1px solid $gray-8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  max-height: 530px;
  overflow-y: auto;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

  hr {
    width: 100%;
    border-top: 1px solid $gray-8;
  }

  p {
    font-family: 'Lato', sans-serif !important;
    font-weight: 500;
  }

  .title-rows {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px;

    strong {
      white-space: nowrap;
    }

    p {
      margin-top: 10px;
    }
  }

  .title-rows:first-child {
    margin-top: 10px;
  }

  .title-rows:last-child {
    margin-bottom: 10px;
  }
}

.family-tree__context-menu__wrapper {
  position: absolute;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  border: 1px solid $gray-8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  max-height: 530px;
  overflow-y: auto;
  max-width: 400px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

  .family-tree__context-menu__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 200px;

    .family-tree__context-menu__menu__item-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:not(.disabled):hover {
        background-color: rgb(244, 244, 244);
      }

      &.disabled {
        cursor: not-allowed;
      }

      .family-tree__context-menu__menu__item-image {
        height: 50px;
        overflow: hidden;
        border-radius: 999;
        user-select: none;
      }
      .family-tree__context-menu__menu__item-title {
        margin-left: 10px;
        max-width: 70%;
        text-overflow: ellipsis;
        font-weight: 700;
        user-select: none;
      }
    }
  }
}

.family-tree__character-context-menu__wrapper {
  position: absolute;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  border: 1px solid $gray-8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  max-height: 530px;
  overflow-y: auto;
  max-width: 400px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

  .family-tree__character-context-menu__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 200px;

    .family-tree__character-context-menu__menu__item-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:hover{
        background-color: rgb(244, 244, 244);
      }

      .family-tree__character-context-menu__menu__item-image {
        height: 50px;
        overflow: hidden;
        border-radius: 999;
        user-select: none;
      }
      .family-tree__character-context-menu__menu__item-title {
        margin-left: 10px;
        max-width: 70%;
        text-overflow: ellipsis;
        font-weight: 700;
        user-select: none;
      }
    }
  }
}

.family-tree__character-add-character-menu__wrapper {
  position: absolute;
  z-index: 999;
  font-family: 'Lato', sans-serif;
  border: 1px solid $gray-8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  max-height: 530px;
  overflow-y: auto;
  max-width: 400px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

  .family-tree__character-add-character-menu__menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 200px;

    .family-tree__character-add-character-menu__menu__item-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:hover:not(.disabled) {
        background-color: rgb(244, 244, 244);
      }

      .family-tree__character-add-character-menu__menu__item-image {
        height: 50px;
        overflow: hidden;
        border-radius: 999;
        user-select: none;
      }
      .family-tree__character-add-character-menu__menu__item-title {
        margin-left: 10px;
        max-width: 70%;
        text-overflow: ellipsis;
        font-weight: 700;
        user-select: none;
      }
      .family-tree__character-add-character-menu__menu__item-title--disabled {
        width: 100%;
        text-overflow: ellipsis;
        font-weight: 700;
        cursor: not-allowed;
      }
    }
  }
}

.family-tree-manager__wrapper {
  position: absolute;
  z-index: 1000;
  left: -300px;
  top: 150px;
  width: 336px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  transition: left 0.2s ease-in-out;

  .family-tree-manager__content {
    max-height: 500px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #fff;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);

    .family-tree-manager__title {
      width: 100%;
      padding-left: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      button {
        margin-left: 10px;
        margin-bottom: 11px;
      }
    }

    .family-tree-manager__list {
      padding: 0px;
      width: 100%;

      .family-tree-manager__item {
        cursor: pointer;
        padding: 10px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid $gray-8;
        &.selected {
          border: 2px dashed $blue-6;
          background-color: $gray-7;
          margin-bottom: 0;
        }
        .family-tree-manager__item__title {
          font-size: 18px;
          font-weight: 700;
          user-select: none
        }
        .family-tree-manager__item__line {
          width: 100%;
          margin: 0px;
        }
        .family-tree-manager__item__buttons {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }
      }
    }
  }
}

.family-tree__anchored-context-menu__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid $gray-8;
  border-radius: 999px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.family_tree__zoom_control_wrapper {
  z-index: 999;
  position: fixed;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 47%;
  right: 50%;
  padding: 4px;
  width: 120px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray-8;
  border-radius: 3px;
  user-select: none;

  span {
    cursor: pointer;
  }

  svg {
    width: 50px;
    cursor: pointer;

    &.disabled {
      color: $neutral-gray-7;
    }
  }
}
