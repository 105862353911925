@import 'colors';
@import 'vars';

$yellow: #ffc72c;

.dashboard__body {
  .account__main {
    width: 100%;

    .plottr-logo {
      display: none;
    }

    .dashboard__account__otter {
      position: relative;
      .otter-speech {
        position: absolute;

        top: 24%;
        right: 260px;
        max-width: 171px;
        word-wrap: break-word;

        h1 {
          font-family: 'Lato', sans-serif !important;
          font-weight: 900;
          font-size: 30px;
          color: #fff !important;
          flex-direction: column !important;

          span {
            margin-right: 5px;

            a {
              margin-left: 5px;
              cursor: pointer;
            }

            &.long-speech {
              margin-top: -30px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

.account-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  .account__main {
    width: 80%;
    margin-top: 100px;

    .plottr-logo {
      margin-top: -50px;
      display: flex;
      align-self: flex-start;
    }
  }
}

.account__main {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;

  .dashboard__account__otter {
    display: flex;
    position: relative !important;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;

    .otter-speech {
      display: flex;
      color: #fff !important;
      gap: 5px;
      max-width: 171px;
      position: absolute !important;
      top: 16%;
      right: 260px;
      word-wrap: break-word;
      text-align: center;

      h1 {
        font-weight: 900 !important;
        font-size: 30px;
        font-family: 'Lato', sans-serif !important;
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-right: 5px;

          a {
            margin-left: 5px;
          }

          &.long-speech {
            margin-top: -35px;
            font-size: 24px;
            letter-spacing: 0.25px;
            line-height: 30px;
          }
        }
      }

      a {
        color: #fff;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    img {
      display: flex;
      width: auto;
      justify-content: flex-end;
      align-items: flex-end;
      height: 225px;
    }
  }
}

.dashboard__user-info.sandboxed__account {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;

  &.canceled {
    opacity: 0.7;
  }

  .dashboard__account__otter {
    position: relative;
  }

  .text-center {
    text-align: center;
  }

  .flex {
    display: flex;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100%;
  }
  .relative {
    position: relative;
  }

  .flex-col {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .items-center {
    align-items: center;
  }

  .dashboard__user-info__section-wrapper {
    border-bottom-left-radius: 5px;
  }

  button {
    border: 1psx solid $blue-7;

    svg {
      fill: #fff !important;
      color: #fff !important;
      height: 24px !important;
      width: 24px !important;
    }

    &.no-border {
      border: none;
    }
  }

  &.pro {
    border: 2px solid $red-5;
    color: #fff;

    > h2 {
      background-color: $red-5;
    }
  }

  &.community {
    border: 2px solid $blue-7;
    color: #fff;

    > h2 {
      background-color: $blue-7;
    }
  }

  &.plottr {
    border: 2px solid $orange;
    color: #fff;

    > h2 {
      background-color: $orange;
    }
  }

  .dashboard__user-info__section-wrapper {
    flex: 1 1 500px;
    box-sizing: border-box;
    align-self: flex-start;
    height: 100%;

    &.full-width {
      flex: none;
      width: 100%;
    }
  }

  .warning-strip {
    padding: 16px;
    background-color: $yellow-8;
    color: $yellow-0;
    width: auto;
    display: flex;
    align-self: flex-start;
    border-radius: 4px;
    align-items: center;
    gap: 5px;
  }

  &.trial {
    border: 2px solid $yellow;
    color: #fff;

    > h2 {
      background-color: $yellow;
    }
  }

  .dashboard__user-info__details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: center;

    &.logout-btn {
      align-items: flex-end;
      margin-bottom: -22px;
    }

    .dashboard__user-info__item {
      display: flex;
      width: 100%;
      > * {
        display: flex;
        width: 100%;
        align-items: center;

        a {
          display: flex;
          width: 100%;
          align-items: center;

          svg {
            height: 20px;
          }
        }
      }

      &.editing {
        align-items: flex-start;
      }

      button {
        width: auto;
        svg {
          fill: #fff;
        }

        &.btn-info {
          &:hover {
            background-color: transparent !important;
            color: $orange-4;
          }
        }
      }

      .info-title {
        font-weight: 700;
        width: 250px;
      }
      .billing {
        @include application-text;
        color: $blue-7;
        font-size: 14pt;
        font-weight: 700;
        display: flex;
        letter-spacing: -0.2px;
      }

      .info-title :has(.billing) {
        width: 200px;
      }

      .dashboard__account__form-error {
        font-size: 14px;
        color: $red-5;

        a {
          text-decoration: underline;
          color: $red-5;
          font-weight: 700;
        }
      }

      .dashboard__acount-password-reset {
        gap: 5px;
        color: $blue-5;
        display: flex;
        align-items: center;

        input {
          width: 250px;
          &.error {
            border-color: $red-5;
          }
        }

        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 16px;
          color: $orange;

          svg {
            color: $orange;
            fill: $orange;
            height: 20px;
          }
        }

        .password-reset-label {
          cursor: pointer;
        }

        .dot-wrapper {
          display: flex;
          gap: 0px !important;
          cursor: auto;
        }

        > div:first-child {
          display: flex;
          align-items: center;
          color: $orange;
        }

        div:first-child {
          gap: 5px;
        }

        .editing {
          cursor: auto;
          flex-direction: column;
          align-items: flex-start !important;
        }

        .btn-success {
          display: flex;
          align-items: center;
        }
      }

      .subscription-status {
        background-color: $red-5;
        text-transform: capitalize;
        font-weight: 700;
        padding: 3px 8px;
        border-radius: 4px;
        color: #fff;

        &.active {
          background-color: $green-5;
        }
        &.trialing {
          background-color: $yellow;
        }
      }
    }

    .dot {
      height: 10px;
      width: 10px;
      margin-right: 1px;
      border-radius: 50%;
      display: inline-block;
      background: $neutral-gray-1;
    }
  }
}
