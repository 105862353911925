@import 'vars';
@import 'colors';

$floating-config-max-margin-top: 160px;
$floating-config-1136-margin-top: 209px;
$floating-config-1024-margin-top: 254px;
$floating-config-1068-margin-top: 209px;
$floating-config-846-margin-top: 304px;
$floating-config-768-margin-top: 325px;
$floating-config-720-margin-top: 378px;
$floating-config-586-margin-top: 420px;
$floating-config-438-margin-top: 467px;
$view-wrapper-margin: 60px auto;
$view-switcher-margin: 40px auto;
$switcher__btn-group__height: 40px;

$btn-group-view-switcher-btn-border: none;
$btn-group-view-switcher-btn-active-background: $gray-9;
$btn-group-view-switcher-btn-background: transparent;
$btn-group-view-switcher-btn-text-color: $blue-1;

.container-with-sub-nav {
  .subnav__container.navbar.navbar-default {
    .container-fluid {
      .view-switcher__nav.nav {
        position: absolute;
        right: auto;
        left: 45%;

        li a {
          padding-top: 2px !important;
        }
      }
      .outline__subnav__right {
        display: initial;
      }
      @media (max-width: 1024px) {
        .outline__subnav__left {
          display: flex;
        }

        .view-switcher__nav__legacy {
          margin: 0;
          padding: 0;
        }

        .outline__subnav__right {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;

          .navbar-nav.navbar-right {
            display: flex;
          }
        }
      }

      @media (max-width: 720px) {
        .outline__subnav__right {
          flex-direction: column;

          .view-switcher__nav {
            position: unset;
          }

          .navbar-nav.navbar-right {
            margin: 0;
          }
        }
      }
    }
  }
  &.plan {
    .outline__plan-view__card-color-switch__wrapper {
      display: flex;
      background-color: #fff;
      border: 1px solid $gray-8;
      padding: 0px 20px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      margin: 0;
      flex-direction: column;
      border-radius: 3px;

      > h3 {
        margin-bottom: 0px;
        margin-top: 12px;
        font-size: 20px;
      }
      .outline__plan-view__card-color-switch {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-bottom: 12px;

        > div:first-child {
          display: flex;
          flex-direction: row;

          > span:first-child {
            margin-right: 12px;
          }
        }

        .option-switch-label {
          background-color: $green-5 !important;
        }
      }
    }

    @media (max-width: 720px) {
      .outline__subnav__right {
        // Page layout config button
        .navbar-nav.navbar-right {
          > li:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.outline__container {
  display: flex;
  overflow: auto;
  width: 100vw;

  &.fulltext,
  &.plan {
    margin-top: 60px;
  }
}

@keyframes configSlideRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.outline__grid {
  width: 100vw;
  padding-left: 0;

  .row {
    height: 100%;
    overflow: hidden;
    &.fulltext {
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      height: 100%;

      .page-layout__config_toggle {
        position: fixed;
        padding: 5px;
        height: max-content;
        left: 0px;
        width: auto !important;

        &.hidden {
          display: none;
        }

        svg {
          height: 25px;
          width: 18px;
        }
      }

      .outline__fulltext__config-container {
        font-family: 'Lato', sans-serif;
        position: fixed;
        border: 1px solid $gray-8;
        border-radius: 3px;
        width: 16%;
        left: 5px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 10px;
        max-height: 530px;
        overflow-y: auto;
        bottom: 10px;
        animation: configSlideRight 0.1s linear !important;

        &.hidden {
          display: none;
        }

        > button:last-child {
          margin-top: 18px;
          width: fit-content;
          align-self: flex-start;
          display: flex;
          align-items: center;
          svg {
            margin-right: 8px;
          }
        }

        hr {
          width: 100%;
          border-top: 1px solid $gray-8;
        }

        p {
          font-family: 'Lato', sans-serif !important;
          font-weight: 500;
        }

        .title-rows {
          font-family: 'Lato', sans-serif;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0px;

          &.disabled {
            * {
              cursor: $cursor-disabled;
              color: $neutral-gray-5;
            }
          }

          label {
            font-weight: 500;
          }

          > label {
            margin-left: 8px;
          }

          > .btn-group {
            margin: 5px 0px;
          }

          .option-switch-wrapper {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;

            .option-switch-label {
              width: 50px;
            }
          }

          &.range {
            margin: 0px 10px;
            margin-top: 10px;
            flex-direction: column;

            > * {
              width: 100%;
            }

            input[type='range'] {
              cursor: pointer;
            }
          }
        }

        .title-rows:first-child {
          margin-top: 10px;
        }

        .title-rows:last-child {
          margin-bottom: 10px;
        }
      }

      .outline__fulltext__zoom-wrapper {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 47%;
        right: 50%;
        padding: 4px;
        width: 120px;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $gray-8;
        border-radius: 3px;
        user-select: none;

        svg {
          width: 50px;
          cursor: pointer;

          &.disabled {
            color: $neutral-gray-7;
          }
        }
      }

      .outline__fulltext__title-container {
        font-family: 'Lato', sans-serif;
        position: fixed;
        border: 1px solid $gray-8;
        border-radius: 3px;
        width: 17%;
        right: 5px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        color: #16222d;
        padding: 10px;

        .title-rows {
          font-family: 'Lato', sans-serif;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          margin: 10px 0px;

          label {
            font-weight: 500;
          }
        }

        p {
          font-family: 'Lato', sans-serif;
          font-weight: 500;
          margin: 0px;
        }
      }

      .page-layout__config_toggle,
      .outline__fulltext__title-container,
      .outline__fulltext__config-container {
        top: $floating-config-max-margin-top;
      }

      @media (max-width: 1136px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-1136-margin-top;
        }
      }

      .page-layout__config_toggle,
      .outline__fulltext__title-container,
      .outline__fulltext__config-container {
        top: $floating-config-max-margin-top;
      }

      @media (max-width: 1136px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-1136-margin-top;
        }
      }

      // the subnav tabs has 2 rows now
      @media (max-width: 1068px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-1068-margin-top;
          width: 240px;
        }
      }

      @media (max-width: 1024px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-1024-margin-top;
        }
      }
      @media (max-width: 846px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-846-margin-top;
        }
      }

      @media (max-width: 768px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-768-margin-top;
        }
      }
      @media (max-width: 720px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-720-margin-top;
        }

        width: 98%;
      }

      @media (max-width: 586px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-586-margin-top;
        }
      }

      @media (max-width: 438px) {
        .page-layout__config_toggle,
        .outline__fulltext__title-container,
        .outline__fulltext__config-container {
          top: $floating-config-438-margin-top;
        }
      }
    }

    &.plan {
      display: flex;
    }

    .outline__grid__minimap {
      height: 100%;
      overflow: auto;
    }

    .outline__grid__beats {
      height: 100%;
      overflow: auto;
      padding: 0;
      position: relative;
      margin: 0 auto;

      &.plan {
        display: flex;
        max-height: 85vh;
        overflow-y: hidden;
        text-align: center;
        width: 95%;

        > div {
          flex: none;
          width: 250px;
          display: flex;
          flex-direction: column;

          h5 {
            font-size: 11pt;
            width: 90%;
          }
        }

        .outline__scene-title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 55px;
          background-color: transparent;

          .outline__beat-manual-sort {
            margin-left: 0;
          }
        }

        .outline__plan-cards-wrapper {
          overflow-y: auto;
          height: 100%;
        }
      }

      &.fulltext {
        width: 100%;
        height: 100%;
        overflow: auto;
        transform-origin: 50% 0%;
      }
    }
  }
}

@media (min-width: 992px) {
  .outline__grid__minimap.col-md-3 {
    width: 20%;
  }

  .outline__grid__beats.col-md-9 {
    width: 80%;
  }
}

.filter-list__list li {
  span.filter-list__placeholder {
    display: inline-block;
    width: 14px;
    margin-right: 4px;
  }

  span.glyphicon {
    width: 14px;
    margin-right: 4px;
  }
}

.outline__beat-manual-sort {
  margin-left: 12px;
  color: $yellow-3;
  cursor: pointer;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: $yellow-1;
  }
}

.outline__card-wrapper {
  margin: 14px 20px;
  background-color: #fff;

  .outline__card__description {
    &:hover,
    &.editing {
      .slate-editor__editor {
        background-color: #fff;
        color: #16222d;
      }
    }
  }

  &.plan {
    position: relative;
    margin: 1px;
    border-radius: 4px;

    .outline__card__description {
      margin: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      > svg:first-child {
        left: -10px;
        top: 10px;
        position: absolute;
        cursor: grab;
      }

      .plan-view__title {
        width: 98%;
        display: flex;
        margin: 0px 8px;
      }

      .outline__card__description__icon-container {
        margin: -3px 0px 0 -5px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 103%;
        top: 14px;
        left: -5px;

        svg:first-child {
          cursor: grab;
          top: 10px;
          position: absolute;
          left: -10px;
          display: flex;
          justify-content: space-between;
        }
      }

      > .left-icons {
        display: flex;
        flex-direction: column;
        height: 100%;
        right: 0px;
        position: absolute;
        top: 0;

        svg {
          cursor: pointer;
        }
      }

      &.editing {
        .outline__card__description__icon-container {
          display: none;
        }
      }
    }

    .outline-list__card-view {
      padding: 5px;
      overflow: hidden;
      background-color: initial;
    }

    .plan-view__expand-button {
      display: none;
    }

    &:hover {
      .outline-list__card-view {
        border: 2px dashed $blue-7;
        background-color: inherit;
      }

      .plan-view__expand-button {
        display: flex;
      }

      .left-icons {
        justify-content: space-between;
      }
    }
  }

  &.fulltext {
    margin: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .outline__card__description {
      margin: 0;
      display: flex;
      flex-direction: column;
      font-size: 11pt;

      svg {
        left: 5px;
        top: 5px;
        cursor: grab;
      }

      .outline__card__description__icon-container {
        margin: -3px 0px 0 -5px;
        display: flex;
        justify-content: space-between;

        svg:first-child {
          cursor: grab;
        }
      }

      &:hover,
      &.editing {
        .slate-editor__editor {
          color: #16222d;
        }
      }

      .fulltext-view__card-title {
        text-align: left;
        margin-top: 0;
        @include sub-header-font;
        font-size: 18px !important;

        &.cardTitleCentered {
          text-align: center;
        }
      }

      .fulltext-view__beat-title {
        text-align: left;
        @include header-font;
        margin-top: 0;

        &.centeredBeatTitle {
          text-align: center;
        }
      }
    }

    .outline-list__card-view {
      padding: 0px 100px;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: none;
      border-bottom: none;
      border-radius: 0%;
      flex-flow: wrap;

      .glyphicon-pencil {
        position: absolute;
        right: -32px;
        margin: 0;
      }

      &:hover {
        .outline__card__description:not(.editing) {
          background-color: inherit;

          .glyphicon-pencil {
            visibility: visible !important;
          }
        }
      }

      .outline__fulltext-wrapper {
        display: flex;
        flex-direction: column;
      }
    }

    .slate-editor__wrapper.readonly {
      margin: 0;

      p {
        margin: 0 !important;
      }

      .slate-editor__word-counter {
        display: none !important;
      }
    }

    .fulltext-view__card-title,
    .fulltext-view__beat-title {
      color: inherit !important;
      margin-bottom: 20px;
    }

    .slate-editor__wrapper .slate-editor__editor p,
    .slate-editor__wrapper .slate-editor__editor ul,
    .slate-editor__wrapper .slate-editor__editor ol {
      font-size: inherit !important;
      color: inherit !important;
    }

    &:hover {
      .fulltext-view__card-title,
      .fulltext-view__beat-title,
      .slate-editor__wrapper .slate-editor__editor p,
      .slate-editor__wrapper .slate-editor__editor ul,
      .slate-editor__wrapper .slate-editor__editor ol {
        color: inherit !important;
      }
    }

    .editing {
      border: initial;
    }
  }

  .outline__card-drop {
    text-align: center;
    margin-bottom: 4px;
    border: 2px dashed $gray-8;
    padding-top: 4px;
    background-color: transparent;

    svg {
      color: $gray-8;
    }
  }

  .outline-list__card-view {
    text-align: left;
    color: inherit;
    background-color: initial;
  }
}

.outline__card__popover-wrapper {
  max-height: 400px;
  overflow-y: auto;

  .slate-editor__wrapper {
    &.plan {
      .slate-editor__word-counter {
        display: none;
      }

      &.slate-editor__wrapper {
        .slate-editor__editor p,
        .slate-editor__editor ul,
        .slate-editor__editor ol {
          font-size: inherit !important;
          color: $darkmode-text !important;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Default view card view styles
.outline-list__card-view {
  @include well;
  padding: 20px;
  max-height: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .outline__card__description {
    &.editing {
      width: 100%;

      .glyphicon-pencil {
        display: none;
      }

      .outline__description__editing {
        .slate-editor__editor {
          .slate-editor__word-counter {
            // space for close button
            bottom: 60px;
          }
        }
      }
    }

    &:not(.editing) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 4px 0px 4px 4px;
      border: 2px dashed transparent;
      border-radius: 4px;
      cursor: pointer;

      .glyphicon-pencil {
        display: block;
        color: $blue-7;
        visibility: hidden;
        margin: 5px;
        align-self: flex-start;
      }
    }
  }

  .outline__card__line-title {
    margin-left: 4px;
  }

  .outline__card-top {
    flex-direction: column;
  }

  .outline__card__line-title,
  .outline__card__grip,
  .outline__card-top {
    display: flex;
    width: 100%;
  }

  .outline__card__grip {
    display: flex;
    align-items: center;
    cursor: move;
    margin-left: -15px;

    svg {
      color: $gray-8;
    }

    h3 {
      margin: 5px 2px;
      // display: inline-block;
    }

    &.editing {
      display: none;
    }

    &.dragging {
      width: 30%;
      overflow: hidden;

      h3 {
        white-space: nowrap;
      }
    }
  }

  .outline__card-bottom {
    margin-top: 8px;
  }

  .chip-cloud {
    margin: 8px 0;
  }

  .outline-divider {
    padding: 10px 40px 2px 40px;
    border-top: 1px solid $gray-8;
    width: 93%;
  }

  &:hover {
    .outline__card__description:not(.editing) {
      border: 2px dashed $blue-7;
      border-radius: 4px;
      background-color: $gray-9;

      .glyphicon-pencil {
        visibility: visible;
      }
    }

    &.fulltext {
      position: relative;

      .glyphicon-pencil {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
}

.outline__card-wrapper {
  &.plan {
    background: transparent;
    margin: 4px 2px;
    &.plotline {
      .outline-list__card-view {
        &:not(:hover) {
          border: none;
        }
      }
    }
    .outline-list__card-view {
      &:hover {
        .outline__card__description:not(.editing) {
          border: none;
        }
      }
    }
  }
}

.outline-list__card-view.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;
  border-color: $darkmode-border;

  &:not(.plan) {
    &:hover {
      .outline__card__description:not(.editing) {
        border: 2px dashed $darkmode-v2-border;
        background-color: $darkmode-v2-background;
      }
    }
  }

  &.plan {
    &:hover {
      border: 2px dashed $darkmode-v2-border;
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.outline__card__label-list {
  margin: 8px 0;

  .label {
    margin-right: 5px;
    padding-top: 4px;
  }
}

.outline__minimap {
  @include well;
  display: block;
  height: calc(100% + 5px);
  overflow-y: auto;
  padding-right: 5px;
  border-radius: 0px;

  li {
    &.active > a {
      background-color: $new-item-background;
    }

    & > a {
      color: inherit;
      cursor: pointer;
      padding: 3px 10px 3px 10px !important;

      &:hover {
        background-color: $body-background-color;
      }
    }
  }
}

.outline__minimap__beat-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .outline__minimap__dots {
    display: flex;
    margin-left: 10px;
    flex-wrap: wrap;
  }

  .outline__minimap__card-dot {
    height: 7px;
    width: 7px;
    margin: 1px;
  }

  &.dropping {
    border: 2px dashed $gray-8;
    padding: 2px;
  }

  > span:first-child {
    display: flex;
    white-space: nowrap;
    margin-right: 5px;
  }

  .accented-text {
    margin-left: 5px;
  }
}

.outline__minimap.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;

  li.active > a {
    background-color: $darkmode-background;

    &:focus {
      background-color: $darkmode-background;
    }
  }

  li > a:hover {
    background-color: $darkmode-background;
    color: $darkmode-text;
  }
}

.outline__scene-wrapper {
  width: 100%;
  &.plan {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    border: 0.5px solid #ddd;

    .outline__card-wrapper {
      &.plan {
        .outline__card-drop {
          border: none;
          margin: 0;
          height: 0px;
          padding: 0;
          transition: height 0.25s linear;

          &.display {
            height: 40px;
          }
        }
      }
    }
  }

  &.fulltext {
    width: 64%;
    margin: 0 auto;
    background-color: #f8f8f8;
    &.showBeatGaps {
      &.previousBeatHasCard {
        margin-top: 30px;
      }

      .outline__card-wrapper {
        .outline-list__card-view {
          padding: 40px 100px;
          border-top: 0.5px solid #ddd;
          border-bottom: 0.5px solid #ddd;
        }
      }
    }

    &:not(.showBeatGaps) {
      .outline-list__card-view {
        padding: 0px 100px;
      }
      .inFirstBeat {
        &.isFirstCard {
          padding-top: 100px;
        }
      }

      .inLastBeat {
        &.isLastCard {
          padding-bottom: 100px;
        }
      }

      :not(.inFirstBeat) {
        &.isFirstCard {
          padding-top: 0;
        }
      }

      :not(.inLastBeat) {
        &.isFirstCard {
          padding-bottom: 0;
        }
      }
    }

    &.showBeatGaps {
      .outline__card-wrapper {
        .outline-list__card-view {
          padding: 40px 100px;
        }
        .isFirstCard {
          padding-top: 100px;
        }
        .isLastCard {
          padding-bottom: 100px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    &.fulltext {
      padding: 0px 10px 0px 24px;
      width: 100%;
    }
  }

  &.inNewBeatDropzone {
    border: 1.5px dashed $gray-5;
    box-sizing: border-box;
  }

  .outline__scene-title {
    height: 42px;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .outline__scene-title {  
    margin-left: 20px;
  }

  &.plan,
  &.fulltext {
    .outline__scene-title {
      margin-left: 0px;
    }
  }

  .outline__scene-title {
    &.darkmode {
      color: $darkmode-text;
    }

    .outline__card-drop {
      text-align: center;
      margin-bottom: 4px;
      border: 2px dashed $gray-8;
      padding-top: 4px;

      svg {
        color: $gray-8;
        height: 20px;
      }
    }
  }
}

.darkmode {
  .outline__grid,
  .outline__container {
    background-color: $darkmode-v2-background;
  }

  .outline__card-wrapper {
    &.fulltext {
      background-color: inherit !important;

      .outline-list__card-view {
        .slate-editor__wrapper {
          .slate-editor__editor {
            p,
            ul,
            ol {
              font-size: inherit !important;
              &:not(.dropdown-menu) {
                color: inherit !important;
              }
            }
          }
        }
      }
    }
  }
  .container-with-sub-nav {
    .outline__plan-view__card-color-switch__wrapper {
      background-color: $darkmode-v2-card-background;
      border: 1px solid $darkmode-v2-border;

      .outline__plan-view__card-color-switch {
        color: $darkmode-v2-text;

        .option-switch-label {
          background-color: #1e7623 !important;
        }
      }
    }
  }

  // All views
  .outline-list__card-view.darkmode {
    background-color: #24252c;
    border-color: $darkmode-v2-border;
  }

  .outline__minimap.darkmode {
    background-color: #24252c;
    color: $darkmode-v2-darker-text;
    border: 0px;
    border-right: 1px solid $darkmode-v2-border;

    li > a:hover,
    li.active > a,
    li.active > a:focus {
      background-color: $darkmode-v2-card-background;
    }
  }

  .outline__scene-title.darkmode,
  .outline-list__card-view {
    &.editing {
      .outline__card__grip svg {
        color: #bbb;
      }
    }

    .outline-divider {
      border-top: 1px solid $darkmode-v2-border;
    }
  }

  .outline__scene-title {
    color: $darkmode-v2-heading-text;
  }

  .outline__scene-wrapper {
    &.plan {
      border: 0.5px solid $darkmode-v2-border;
    }

    &.inNewBeatDropzone {
      border: 1.5px dashed $gray-5;
    }

    &.fulltext {
      background-color: $darkmode-v2-background;
    }
  }

  .outline__card-wrapper {
    &.fulltext {
      &.editing {
        .slate-editor__editor {
          background-color: #fff;
          color: #16222d !important;
        }
      }

      .slate-editor__editor {
        color: inherit !important;
        background-color: inherit;

        &:hover {
          background-color: #fff !important;
        }
      }

      &:hover {
        color: inherit !important;

        &.editing {
          color: inherit !important;
          background-color: inherit !important;
        }

        .fulltext-view__card-title,
        .fulltext-view__beat-title,
        .outline__card__description h5,
        .slate-editor__wrapper .slate-editor__editor p,
        .slate-editor__wrapper .slate-editor__editor ul,
        .slate-editor__wrapper .slate-editor__editor ol {
          color: inherit !important;
        }
      }
    }
  }

  .outline__grid {
    .outline__grid__beats {
      &.plan {
        > div {
          h5 {
            color: inherit;
          }
        }
      }

      &.fulltext {
        .outline-list__card-view {
          color: inherit !important;
          &:hover {
            .outline__card__description:not(.editing) {
              background-color: inherit;

              .glyphicon-pencil {
                visibility: visible !important;
              }
            }
          }

          &.isFirstCard {
            &.showBeatTitle,
            &.inFirstBeat {
              border-top: 1px solid $darkmode-v2-border;
            }
          }

          &.isLastCard {
            &.showBeatTitle,
            &.inLastBeat {
              padding-bottom: 100px;
              border-bottom: 1px solid $darkmode-v2-border;
            }
          }

          &.isFirstCard {
            border-top: 1px solid $darkmode-v2-border;
          }

          &.isLastCard {
            padding-bottom: 100px;
            border-bottom: 1px solid $darkmode-v2-border;
          }
        }

        .outline__card__description:not(.editing) {
          &:hover {
            border: 2px dashed $darkmode-v2-border;
          }
        }

        .outline__card__description {
          &:hover,
          &.editing {
            .slate-editor__editor {
              background-color: #fff;
              color: #16222d !important;
            }
          }
        }
      }

      &:not(.fulltext) {
        .outline__scene-wrapper {
          .outline__card-wrapper {
            background-color: transparent;

            .outline-list__card-view {
              .outline__card__description {
                &:hover,
                &.editing {
                  .slate-editor__editor {
                    background-color: inherit;
                  }
                }
              }
            }
          }
        }
      }
    }

    .fulltext {
      .outline__fulltext__title-container,
      .outline__fulltext__config-container {
        background-color: $darkmode-v2-background;
        border: 1px solid $darkmode-v2-border;
        color: $darkmode-v2-text;

        hr {
          border-top: 1px solid $darkmode-v2-border;
        }
      }

      .outline__fulltext__zoom-wrapper {
        background-color: $darkmode-v2-background;
        border: 1px solid $darkmode-v2-border;
        color: $darkmode-v2-text;

        svg {
          &.disabled {
            color: $neutral-gray-2;
          }
        }
      }
    }
  }
}

@media print {
  .darkmode,
  :not(.darkmode) {
    .plan {
      @page {
        margin: 1rem !important;
      }
      &.outline__container {
        width: 100%;
        margin: 0px;
        overflow: visible;
      }
      .outline__grid {
        overflow: visible;
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
      }
      .row.plan {
        display: flex;
        overflow: visible;
        flex-direction: row;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
      }

      .outline__grid__beats {
        flex: none;
        width: 100%;
        margin: 0px;
        background: initial !important;
        color: initial !important;
        height: 100%;
        page-break-inside: avoid;
        writing-mode: vertical-lr;
      }

      .outline__grid__beats.plan {
        height: 100%;
        width: 100%;
        max-height: 100vh;
        max-width: 100vw;
        overflow: auto;
        margin: 0px;
      }

      .outline__scene-wrapper {
        min-width: 1000px;
        height: auto;
        page-break-inside: avoid;
        writing-mode: vertical-rl;
      }

      .outline__beat-manual-sort {
        display: none;
      }

      h3.outline__scene-title {
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        page-break-inside: avoid;
        text-align: center;
        margin: 0px 20px;
        height: 100%;
        color: #000 !important;

        > span {
          color: #000 !important;
          text-align: center;
          margin: auto;
        }
      }

      .outline__plan-cards-wrapper {
        height: 100% !important;
        text-overflow: ellipsis;
        overflow: unset;
        width: fit-content;
        height: 100%;
        display: flex !important;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        writing-mode: horizontal-tb;
      }
      
      .outline__card-wrapper {
        margin: 1px !important;
        margin: auto;
        display: flex;
        width: fit-content;
        page-break-inside: avoid;
        writing-mode: vertical-rl;
        flex-direction: column;
        height: 250px;
        flex-wrap: wrap;
      }

      .outline-list__card-view {
        width: fit-content;
        margin: auto;
        text-overflow: ellipsis;
        text-align: left;
        height: 100%;
      }

      .plan-view__title {
        text-overflow: ellipsis;
        margin: 12px;
        h5 {
          font-size: 14px;
          margin: 0px;
        }
      }
      svg {
        display: none;
      }
    }

    .fulltext {
      width: 100%;
      margin: 0px;

      .outline__grid__beats {
        transform: scale(1);
        margin: 0px;
        width: 100%;
      }
      .outline__scene-wrapper.fulltext {
        width: 100%;
        border: none;
        padding: 0px;
        margin: 0px;
        color: #000 !important;
        background: transparent !important;

        .outline-list__card-view {
          border: none;
          padding: 0px;
          margin: 0px;
          width: 100%;
          color: #000 !important;
          background: transparent !important;

          .outline__card__description {
            * {
              color: #000 !important;
              background: transparent !important;
            }
          }
        }
      }

      .outline__fulltext__title-container,
      .outline__fulltext__config-container {
        display: none;
      }
    }
  }
}

@page {
  margin: 3rem !important;
}
